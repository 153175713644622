#main-nav {
  background: gray;
  position: sticky;
  top: 0;
  z-index: 1;
  padding: 0.5em;
}

#main-nav a {
  color: black;
  text-decoration: none;
}

#main-nav .container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  justify-content: center;
}

#main-nav .btn-login {
  justify-self: right;
  background-color: gray;
}

#main-nav button {
  background-color: gray;
}

#main-nav i.fas {
  color: black;
  background-color: gray;
}